var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"px-4 sm:px-0"},[_c('div',{staticClass:"container mx-auto flex h-full flex-col items-center py-16 sm:justify-center sm:py-0"},[_c('form',{staticClass:"login w-full sm:w-auto",attrs:{"novalidate":"true"},on:{"submit":function($event){$event.preventDefault();return _vm.login.apply(null, arguments)}}},[_c('h1',{staticClass:"mb-4 text-2xl font-semibold"},[_vm._v("Login")]),(_vm.hasError)?_c('div',{staticClass:"error-msg mb-4 rounded border-2 border-red-400 bg-red-100 p-4 text-red-400"},[_vm._v(" The email address or password you entered is incorrect. ")]):_vm._e(),_c('div',{staticClass:"input-group"},[_c('label',[_vm._v("Email")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.loginCredentials.email),expression:"loginCredentials.email"}],ref:"email",attrs:{"required":"","type":"text","placeholder":"Email"},domProps:{"value":(_vm.loginCredentials.email)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.loginCredentials, "email", $event.target.value)}}})]),_c('div',{staticClass:"input-group"},[_c('label',{staticClass:"flex w-full items-center justify-between"},[_c('span',[_vm._v("Password")]),_c('div',{on:{"click":function($event){return _vm.$store.dispatch('sendMpEvent', {
                event: 'Forgotten Password',
                properties: {
                  location: _vm.$route.name,
                  type: 'EVT',
                },
              })}}},[_c('router-link',{staticClass:"text-sm text-teal-400 hover:underline",attrs:{"tabindex":"-1","to":{ name: 'forgotten password' }}},[_vm._v("Forgotten password?")])],1)]),_c('password-input',{ref:"password",attrs:{"isFocused":_vm.hasError,"placeholder":"Password","required":"","canView":true},model:{value:(_vm.loginCredentials.password),callback:function ($$v) {_vm.$set(_vm.loginCredentials, "password", $$v)},expression:"loginCredentials.password"}})],1),_c('loading-button',{attrs:{"is-loading":_vm.isLoading,"is-enabled":_vm.formValid,"is-full":true,"label":"Login"}})],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }