<template>
  <div class="px-4 sm:px-0">
    <div
      class="container mx-auto flex h-full flex-col items-center py-16 sm:justify-center sm:py-0"
    >
      <form
        class="login w-full sm:w-auto"
        @submit.prevent="login"
        novalidate="true"
      >
        <h1 class="mb-4 text-2xl font-semibold">Login</h1>
        <div
          v-if="hasError"
          class="error-msg mb-4 rounded border-2 border-red-400 bg-red-100 p-4 text-red-400"
        >
          The email address or password you entered is incorrect.
        </div>
        <div class="input-group">
          <label>Email</label>
          <input
            v-model="loginCredentials.email"
            required
            ref="email"
            type="text"
            placeholder="Email"
          />
        </div>
        <div class="input-group">
          <label class="flex w-full items-center justify-between"
            ><span>Password</span>
            <div
              @click="
                $store.dispatch('sendMpEvent', {
                  event: 'Forgotten Password',
                  properties: {
                    location: $route.name,
                    type: 'EVT',
                  },
                })
              "
            >
              <router-link
                class="text-sm text-teal-400 hover:underline"
                tabindex="-1"
                :to="{ name: 'forgotten password' }"
                >Forgotten password?</router-link
              >
            </div></label
          >

          <password-input
            v-model="loginCredentials.password"
            :isFocused="hasError"
            placeholder="Password"
            required
            :canView="true"
            ref="password"
          />
        </div>

        <loading-button
          :is-loading="isLoading"
          :is-enabled="formValid"
          :is-full="true"
          label="Login"
        />
      </form>
    </div>
  </div>
</template>

<script>
import store from "@/store";
import LoadingButton from "@/components/LoadingButton";
import PasswordInput from "@/components/auth/PasswordInput";
import utils from "@/utils";
export default {
  metaInfo: {
    title: "Login",
  },
  data() {
    return {
      loginCredentials: {
        email: this.$route.params.email ? this.$route.params.email : "",
        password: "",
      },
      hasError: false,
      isLoading: false,
      utils,
    };
  },
  components: {
    LoadingButton,
    PasswordInput,
  },
  computed: {
    formValid() {
      let self = this;
      // loop over all contents of the fields object and check if they exist and valid.
      return Object.keys(this.loginCredentials).every((field) => {
        return self.loginCredentials[field] && self.loginCredentials[field];
      });
    },
  },
  methods: {
    login: function () {
      let email = this.loginCredentials.email;
      let password = this.loginCredentials.password;
      let self = this;
      this.isLoading = true;
      store
        .dispatch("login", { email, password })
        .then(() => {
          store.dispatch("getUser").then(function (user) {
            self.$store.commit("set_mp_id", self.utils.getMpId(user.id));

            self.$store.dispatch("sendMpEvent", {
              event: "Logged In",
              properties: {
                location: self.$route.name,

                type: "EVT",
              },
            });
            self.hasError = false;

            if (self.$route.query.next) {
              self.$router.replace(self.$route.query.next);
            } else {
              self.$router.push({ name: "dashboard" }).catch((err) => {
                console.log(err);
              });
            }
          });
        })
        .catch((err) => {
          this.isLoading = false;
          this.hasError = true;
          throw Error(err.response.data.error.message);
        });
    },
  },
  mounted() {
    if (this.$route.params.email) {
      this.$refs.password.focus();
    } else {
      this.$refs.email.focus();
    }
  },
};
</script>

<style lang="scss" scoped>
.login,
.error-msg {
  max-width: 360px;
  width: 100%;
}
</style>
